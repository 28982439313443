import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../lib/hook';
import { useNavigate } from "react-router-dom";
import {
    Box
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";


import { fetchChats, chatSelector, Chat, fetchChat, setSelectedChatId, setAlert } from '../lib/ducks/chatDuck';
import { authSelector } from '../lib/ducks/authDuck';

import AlertHandler from '../components/alertHandler';
import { ChatApi } from '../lib/apiActions';
import { LoadingScreen } from '../components/LoadingScreen';
import { TopBar } from '../components/Topbar';
import { NavigationDrawer } from '../components/NavigationDrawer';
import { ChatDrawer } from '../components/ChatDrawer';
import { useFormDialog } from '../components/dialogs/FormDialog';
import { OnboardDialog } from '../components/dialogs/onboardingDialog';

const { palette } = createTheme();


declare module "@mui/material/styles" {

    interface Palette {
        bubbleColor: any;
        boxShade: any;
        responseBubbleColor: any;
        sidePanel: any;
    }

    interface PaletteOptions {
        bubbleColor: any;
        boxShade: any;
        responseBubbleColor: any;
        sidePanel: any;
    }
}

const themeDark = createTheme({
    palette: {
        mode: 'dark',
        bubbleColor: "#3A2C54",
        responseBubbleColor: "#243B55",
        boxShade: '#23272A',
        sidePanel: '#171526',
        background: {
            paper: '#171526'
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: "#1F1D2B",
                    backgroundImage: `linear-gradient(19deg, #1F1D2B 0%, #1C1B29 100%)`,
                    backgroundRepeat: 'no-repeat'
                },
            },
        },
    },

});

const themeLight = createTheme({
    palette: {
        mode: 'light',
        bubbleColor: "#D5E5F6",
        responseBubbleColor: "#DDE1E7",
        sidePanel: "#171526",
        boxShade: '#DDE1E7',
        background: {
            //paper: '#E3E4EB'
            paper: '#D3D6DF'

        },
        primary: {
            main: "#3f50b5",
            contrastText: "#2C2F33"

        },
        text: {
            primary: "#2C2F33"
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: "#FFFFFF",
                    backgroundImage: `linear-gradient(to bottom, #FFFFFF, #F3F4F6)`,
                    //backgroundImage: `linear-gradient(to bottom, red, #F3F4F6)`,
                    backgroundRepeat: 'no-repeat',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: "#F8F8F8"
                }
            }
        },
        MuiMenu: {
            defaultProps: {
                elevation: 0,
            },
            styleOverrides: {
                paper: {
                    background: '#FCFCFC',
                    border: 1,
                    borderColor: '#E6E6E9',
                    borderStyle: 'solid',
                    borderRadius: '8px'
                },
                list: {
                    padding: '8px',
                },

            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    padding: '16px',
                    borderRadius: '8px',
                },

            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    background: '#FCFCFC',
                    border: 1,
                    borderColor: '#E6E6E9',
                    borderStyle: 'solid',
                    borderRadius: '8px'
                }
            }
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {

                root: {

                    borderRadius: '8px',
                    padding: '12px 24px',
                    textTransform: "none",
                },
                containedPrimary: {
                    backgroundColor: '#3A52D1',
                    color: '#FFFFFF',
                },
                outlinedPrimary: {
                    color: '#545455',
                    borderColor: '#545455',
                },
                textPrimary: {
                    color: '#545455',
                }
            }
        }
    },

});



const drawerWidth = 320;
const navigationDrawerWidthMin = 80;
const navigationDrawerWidthMax = 240;


interface ComponentProps {
    children: any,
}

const MainLayout: React.FC<ComponentProps> = ({ children }) => {
    const navigate = useNavigate()
    const [chatDrawerOpen, setChatDrawerOpen] = useState(false);
    const dispatch = useAppDispatch();
    const firstLoading = useAppSelector(chatSelector).firstLoading
    const darkTheme = useAppSelector(authSelector).darkTheme
    const firstName = useAppSelector(authSelector).user?.first_name
    const [getForm, FormDialog] = useFormDialog();
    //const [getWelcomeForm, WelcomeDialog] = useWelcomeDialog();
    const userRedux = useAppSelector(authSelector).user
    const [onboardOpen, setonboardOpen] = useState(false);


    useEffect(() => {
        dispatch(fetchChats());
        if (firstName != "")
            setChatDrawerOpen(true);
        //dispatch(fetchUser());
    }, [])

    useEffect(() => {
        if (!firstLoading && firstName === "")
            setonboardOpen(true);
    }, [firstLoading])

    const handleNewChat = async () => {
        //const createResponse = await ChatApi.create({}, null);
        //setSelectedId(createResponse.id);
        dispatch(setSelectedChatId(null));
        navigate('/')
        //dispatch(fetchChat(createResponse.id));
    };


    const handleDrawerOpen = () => {
        setChatDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setChatDrawerOpen(false);
    };

    const handleChatChange = (conversation: Chat, id: string) => {
        dispatch(setSelectedChatId(id));
        dispatch(fetchChat(conversation.id));
        navigate('/');
    };


    const handleFeedback = async () => {
        const result = await getForm({ title: 'Palaute', text: '', okText: 'Lähetä', cancelText: 'Peruuta', inputPlaceholder: '' })

        if (!result.status) return;
        const response = await ChatApi.generalFeedback({ text: result.input }, { validateStatus: false });
        if (response.status < 300) {
            dispatch(setAlert({ message: 'Palaute lähetetty', type: 'success', key: (new Date()).toString() }))
        } else {
            dispatch(setAlert({ message: 'Palautteen lähetys epäonnistui', type: 'error', key: (new Date()).toString() }))
        }
    }


    if (firstLoading) {
        return (<LoadingScreen />)
    }


    return (
        <ThemeProvider theme={darkTheme ? themeDark : themeLight}>
            <CssBaseline enableColorScheme />
            <FormDialog />
            <OnboardDialog
                userRedux={userRedux}
                open={onboardOpen}
                setOpen={setonboardOpen}
            />
            <TopBar
                drawerWidth={drawerWidth}
                leftDrawerOpen={chatDrawerOpen}
                handleNewChat={handleNewChat}
                handleDrawerClose={handleDrawerClose}
                handleDrawerOpen={handleDrawerOpen}
                handleFeedback={handleFeedback}
            />


            <Box sx={{ display: 'flex', height: '100%' }}>

                <NavigationDrawer
                    drawerWidthMin={navigationDrawerWidthMin}
                    drawerWidthMax={navigationDrawerWidthMax}
                    handleDrawerClose={handleDrawerClose}
                    handleDrawerOpen={handleDrawerOpen}
                    handleFeedback={handleFeedback}
                    handleNewChat={handleNewChat}
                />

                <ChatDrawer
                    handleChatChange={handleChatChange}
                    handleDrawerClose={handleDrawerClose}
                    handleDrawerOpen={handleDrawerOpen}
                    handleNewChat={handleNewChat}
                    drawerWidth={drawerWidth}
                    navigationDrawerWidthMin={navigationDrawerWidthMin}
                    open={chatDrawerOpen}
                />

                <Box sx={{
                    //transform: 'scale(1)',
                    width: '100%', height: 'calc(100vh - 64px)', display: 'flex', justifyContent: 'center'
                }}>

                    {children}
                </Box>
            </Box >
            <AlertHandler />
        </ThemeProvider >
    )
}

export default MainLayout;