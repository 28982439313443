import { Box, Grid, Link, Typography } from "@mui/material"
import { useAppSelector } from "../../lib/hook";
import { listSelector, SearchResult } from "../../lib/ducks/listDuck";
import { Fragment } from "react";

interface searchResultElementProps {
    result: SearchResult
}

const SearchResultElement: React.FC<searchResultElementProps> = ({ result }) => (
    <Box>
        <Link
            href={result.metadata.url ?? ""}
        >
            <Typography
                sx={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 1,
                }}
                variant="body1">
                {result.metadata.title}
            </Typography>

        </Link>
        <Typography fontSize={'12px'} fontWeight={'light'} >{result.metadata.url}</Typography>
        <Typography
            sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
            }}
            fontSize={'13px'} fontWeight={400}
            variant="body1">
            {result.text}
        </Typography>
    </Box>

)

export const ListResult = () => {
    const results = useAppSelector(listSelector).search;

    return (
        <>
            {
            <Grid container marginTop={2} spacing={2} paddingRight={1}>
                <Grid item xs={10}>
                    Otsikko
                </Grid>

                <Grid item xs={2}>
                    Lähde
                </Grid>

            </Grid>
            }
            <Box overflow={'auto'} marginTop={2}>
                <Grid container spacing={2}>
                    {results?.search_results?.map((result: SearchResult, index: number) => (
                        <Fragment key={"result" + index}>
                            <Grid item xs={10}>
                                <SearchResultElement result={result} />
                            </Grid>
                            <Grid item xs={2}>
                                <Box display={'flex'} alignContent={'center'}>
                                    <Typography>
                                        {result.metadata.table || ""}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Fragment>
                    ))}
                </Grid>
            </Box>
        </>
    )
}
