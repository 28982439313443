import { Avatar } from "@mui/material";

/*
function stringToColor(string: string) {

    let hash = 0;
    let i;

    // eslint-disable no-bitwise 
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    // eslint-enable no-bitwise 
    return color;
}

function stringAvatar(name: string | undefined) {
    if (name == undefined) {
        return {
            sx: {
                bgcolor: stringToColor("unknown person"),
            },
            children: `unknown person`,
        };
    }
    return {
        children: `${name.split('.')[0][0].toUpperCase()}${name.split('.')[1][0].toUpperCase()}`,
    };
}

interface AvatarProps {
    email: string | undefined;
    size: number;
    sx: any;
}

*/

interface AvatarProps {
    avatarName: string | undefined
    size: number;
    sx?: any;
}

//Recevied Message
/*
export const StyledAvatar: React.FC<AvatarProps> = ({ email, size, sx }) => {

    return (
        <Avatar {...stringAvatar(email)} sx={{
            ...sx, width: `${size}px`, height: `${size}px`, bgcolor: stringToColor(email ?? "unknown"),
        }} />
    );
}
    */

export const StyledAvatar: React.FC<AvatarProps> = ({ avatarName, size, sx }) => (
    <Avatar src={"/images/avatar/" + ((!avatarName || avatarName === "") ? "default.png" : avatarName)} sx={{
        ...sx, width: `${size}px`, height: `${size}px`,
    }} />
)

export default StyledAvatar;