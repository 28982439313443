import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../lib/hook";
import { authSelector, changePassword, deleteUserAndLogout, updateUserAndFetch, User, UserInfo } from "../lib/ducks/authDuck";
import StyledAvatar from "../components/avatar";
import { useAvatarDialog } from "../components/dialogs/AvatarDialog";

interface RemoveUserComponentProps {
    onRemoveUser: any
}


const RemoveUserView: React.FC<RemoveUserComponentProps> = ({ onRemoveUser }) => {
    const [state, setState] = useState(false);
    return (
        <>
            <Box>
                <Typography>Poista käyttäjä?</Typography>
            </Box>
            <Box
                display={'flex'}
                flex={1}
                justifyContent={'space-between'}
                alignContent={'center'}
            >
                <FormControlLabel required control={<Checkbox value={state} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setState(event.target.checked);
                }} />} label="Vahvistan tilin poistamisen" />
            </Box>
            <Box>

                <Button
                    variant='contained'
                    color="error"
                    sx={{
                        backgroundColor: '#FF999C'
                    }}
                    disabled={!state}
                    onClick={onRemoveUser}
                >
                    Poista käyttäjä
                </Button>
            </Box>
        </>

    );
}

interface ComponentProps {
    user: UserInfo | null
    setUser: any
    onUserSave: any
    onSetUserDefaults: any
    onSetDefaultAvatar: any
}


const UserInfoView: React.FC<ComponentProps> = ({ user, setUser, onUserSave, onSetUserDefaults, onSetDefaultAvatar }) => {
    const saveEnabled = user?.last_name != '' && user?.first_name != ''

    const [getForm, AvatarDialog] = useAvatarDialog();

    const onChangeAvatar = async () => {
        console.log("avatarName", user?.avatar_name)
        const { status, avatarName } = await getForm({ nameNeeded: false, avatarName: user?.avatar_name })

        console.log(status)
        if (status)
            setUser({ ...user, avatar_name: avatarName })
    }

    return (
        <>
            <AvatarDialog />
            <Box
                display={'flex'}
                flex={1}
                flexDirection={'row'}
                marginBottom={2}
            >
                <StyledAvatar avatarName={user?.avatar_name} size={100} sx={{}} />
                <Box
                    display={'flex'}
                    flex={1}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    marginLeft={2}
                >
                    <Box marginBottom={1}>
                        <Button
                            variant="contained"
                            onClick={onChangeAvatar}
                        >
                            Vaihda kuvake</Button>
                        <Button
                            variant="outlined"
                            onClick={onSetDefaultAvatar}
                            sx={{
                                backgroundColor: 'transparent',
                                color: '#F93A37',
                                borderColor: '#F93A37',
                                marginLeft: 2
                            }}
                        >Nollaa kuvake</Button>
                    </Box>
                    <Typography fontWeight={10}>Valitse itsellesi kuvake</Typography>
                </Box>

            </Box>
            <Box
                display={'flex'}
                flex={1}
                justifyContent={'space-between'}
                alignContent={'space-between'}
            >
                <TextField
                    label='Etunimi'
                    value={user?.first_name}
                    required={true}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, first_name: event.target.value })}
                    sx={{ width: '460px' }}
                />
                <TextField
                    label='Sukunimi'
                    value={user?.last_name}
                    required={true}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, last_name: event.target.value })}
                    sx={{ width: '460px' }}
                />
            </Box>
            <Box
                display={'flex'}
                flex={1}
                justifyContent={'space-between'}
                alignContent={'space-between'}
            >
                <TextField
                    value={user?.email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, email: event.target.value })}
                    disabled={true}
                    label='Sähköposti'
                    sx={{ width: '460px' }}
                />
                <TextField
                    value={user?.organization}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, organization: event.target.value })}
                    label='Organisaatio'
                    sx={{ width: '460px' }}
                />
            </Box>
            <Box
                display={'flex'}
                flex={1}
            >
                <TextField
                    value={user?.phone_number}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, phone_number: event.target.value })}
                    label='Puhelin'
                    sx={{ width: '460px' }}
                />
            </Box>
            <Box>
                <Button
                    variant='contained'
                    sx={{ marginRight: '16px' }}
                    onClick={onUserSave}
                    disabled={!saveEnabled}
                >
                    Tallenna muutokset
                </Button>
                <Button
                    variant='outlined'
                    onClick={onSetUserDefaults}
                >
                    Nollaa muutokset
                </Button>
            </Box>
        </>
    );
}

interface ChangePasswordComponentProps {
    onChangePassword: any
}

const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{12,}$/;
const ChangePasswordView: React.FC<ChangePasswordComponentProps> = ({ onChangePassword }) => {

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPassword2, setNewPassword2] = useState("");
    const [errors, setErrors] = useState({ newPassword: false, newPassword2: false })

    const handleChangePassword = () => {
        if (!passwordRegex.test(newPassword)) {
            setErrors(o => ({ ...o, newPassword: true }))
            return
        }
        onChangePassword(oldPassword, newPassword);
    }

    return (
        <>
            <Box
                display={'flex'}
                flex={1}
                justifyContent={'space-between'}
                alignContent={'space-between'}

            >
                <TextField
                    type="password"
                    label='Vanha salasana'
                    value={oldPassword}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOldPassword(event.target.value)}
                    sx={{ width: '460px' }}
                />
            </Box>
            <Box>
                <Typography variant="caption" display="block">
                    Salasanan pituus tulee olla vähintään 12 merkkiä.
                </Typography>
                <Typography variant="caption" display="block">
                    Salasanassa tulee olla vähintään yksi iso kirjain, yksi pieni kirjain ja yksi numero.
                </Typography>
                <Typography variant="caption" display="block">
                    Salasana ei saa olla yleisesti käytettyjen salasanojen joukossa.
                </Typography>
                <Typography variant="caption" display="block">
                    Salasanassa ei saa olla viittauksia käyttäjän tietoihin.
                </Typography>
            </Box>
            <Box
                display={'flex'}
                flex={1}
                justifyContent={'space-between'}
                alignContent={'space-between'}
            >
                <TextField
                    type="password"
                    label='Uusi salasana'
                    value={newPassword}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewPassword(event.target.value)}
                    sx={{ width: '460px' }}
                    error={errors.newPassword}
                    helperText={errors.newPassword ? "Tarkista salasanan vaatimukset" : ""}
                />
                <TextField
                    type="password"
                    label='Uusi salasana uudestaan'
                    value={newPassword2}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewPassword2(event.target.value)}
                    sx={{ width: '460px' }}
                    error={errors.newPassword2}
                    helperText={errors.newPassword2 ? "Salasanat eivät täsmää" : ""}
                />
            </Box>
            <Box>
                <Button
                    variant='contained'
                    sx={{ marginRight: '16px' }}
                    onClick={handleChangePassword}
                    disabled={newPassword != newPassword2 || newPassword === "" || oldPassword === ""}
                >
                    Tallenna
                </Button>
            </Box>
        </>
    );

}

export const ProfileView = () => {
    const userRedux = useAppSelector(authSelector).user
    const [user, setUser] = useState<UserInfo | null>(userRedux);
    const [userViewSelected, setUserViewSelected] = useState(true);
    const dispatch = useAppDispatch()

    useEffect(() => {
        onSetUserDefaults();
    }, [userRedux])

    const onUserSave = () => {
        console.log("user", user)
        if (user)
            dispatch(updateUserAndFetch(user));
    }
    const onRemoveUser = () => {
        dispatch(deleteUserAndLogout())
    }
    const onSetUserDefaults = () => {
        if (userRedux) {
            const newObject = {
                first_name: userRedux.first_name,
                last_name: userRedux.last_name,
                organization: userRedux.organization,
                email: userRedux.email,
                phone_number: userRedux.phone_number,
                avatar_name: userRedux.avatar_name
            }
            setUser(newObject as UserInfo)
        }
    }

    const onSetDefaultAvatar = () => {
        if (userRedux && user != null) {
            setUser({ ...user, avatar_name: userRedux.avatar_name })
        }
    }

    const onChangePassword = (oldPassword: string, newPassword: string) => {
        dispatch(changePassword({ old_password: oldPassword, password: newPassword }))
    }

    return (
        <Box
            display="flex"
            justifyContent="center"
            flexDirection={'column'}
            flex={1}
            maxWidth={'1011px'}
        >
            {userRedux?.first_name === "" &&
                <Typography paddingBottom={2}>Ennen kuin aloitat, täytä alla olevat tiedot.</Typography>
            }
            <Box>
                <Button
                    variant={userViewSelected ? 'contained' : 'text'}
                    sx={{ marginRight: '16px' }}
                    onClick={() => setUserViewSelected(true)}
                >
                    Tili
                </Button>
                <Button
                    variant={!userViewSelected ? 'contained' : 'text'}
                    onClick={() => setUserViewSelected(false)}
                >
                    Turvallisuus
                </Button>
            </Box>
            <Box
                marginTop={'16px'}
                display="flex"
                //justifyContent="center"
                flexDirection={'column'}
                flex={1}
                maxWidth={'1011px'}
                maxHeight={'490px'}
                sx={{ backgroundColor: '#FCFCFC', borderStyle: 'solid', border: 1, borderRadius: '8px', borderColor: '#E6E6E9' }}
                padding={'24px 32px'}
            >
                {userViewSelected ?
                    <UserInfoView user={user} setUser={setUser} onUserSave={onUserSave} onSetUserDefaults={onSetUserDefaults} onSetDefaultAvatar={onSetDefaultAvatar} />
                    :
                    <ChangePasswordView onChangePassword={onChangePassword} />
                }

            </Box>

            {userViewSelected &&
                <Box
                    marginTop={'16px'}
                    display="flex"
                    //justifyContent="center"
                    flexDirection={'column'}
                    flex={1}
                    maxWidth={'1011px'}
                    maxHeight={'190px'}
                    sx={{ backgroundColor: '#FCFCFC', borderStyle: 'solid', border: 1, borderRadius: '8px', borderColor: '#E6E6E9' }}
                    padding={'24px 32px'}
                >

                    <RemoveUserView onRemoveUser={onRemoveUser} />


                </Box>
            }

        </Box>
    );
}