import React, { useState, useEffect } from 'react';

import {
    Button,
    Dialog,
    TextField,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,

} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
interface InputDialogProps {
    title: string,
    text: string,
    multiline: boolean,
    okText: string,
    cancelText: string,
    open: boolean,
    handleClose: any,
    submit: any,
    required: boolean
    orgText?: string | null
}

export const InputDialog: React.FC<InputDialogProps> = ({ title, text, multiline, okText, cancelText, open, handleClose, submit, required, orgText }) => {
    const [inputText, setInputText] = useState('');
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (open) {
            setLoading(false);
            if (orgText) {
                setInputText(orgText)
            }
        }
    }, [open])

    const handleSubmit = () => {
        setLoading(true)
        submit(inputText);
        setInputText('');
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {text}
                </DialogContentText>
                <TextField
                    multiline={multiline}
                    autoFocus
                    required={required}
                    value={inputText}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setInputText(event.target.value);
                    }}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{cancelText}</Button>
                <LoadingButton loading={isLoading} disabled={inputText === '' && required} onClick={handleSubmit}>{okText}</LoadingButton >
            </DialogActions>
        </Dialog>
    )
}




interface AlertDialogProps {
    title: string,
    text: string,
    okText: string,
    cancelText: string,
    open: boolean,
    handleClose: any,
    submit: any,
}

export const AlertDialog: React.FC<AlertDialogProps> = ({ title, text, okText, cancelText, open, handleClose, submit }) => {
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (open) {
            setLoading(false);
        }
    }, [open])
    const handleSubmit = () => {
        setLoading(true);
        submit();
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        //fullWidth
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{cancelText}</Button>
                <LoadingButton loading={isLoading} onClick={handleSubmit} autoFocus>
                    {okText}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}