

export const getPipeColor = (role) => {

    switch (role) {
        case "Työ":
            return "#D4714C"
        case "Perintö":
            return "#7B4397"
        case "default":
            return "#3A52D1"

        default:
            return null
    }
}
