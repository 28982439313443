import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
//import { LoginApi, UserApi } from "../apiActions";
import { RootState } from "../store";
import { ListSearchApi } from "../apiActions";

export interface Search {
    search_id: string,
    title: string,
    search_results: SearchResult[]
}

export interface SearchResult {
    text: string,
    id_: string,
    score: number,
    metadata: SearchResultMetadata
}

export interface SearchResultMetadata {
    title?: string
    href?: string
    url?: string
    table?: string
}

export interface SearchRequest {
    message: string,
    exclude_ids: string[],
    source_tables: string[]
}

interface SearchState {
    search: Search | null,
    isLoading: boolean,
    initial: boolean
}

export const initialState = {
    search: null,
    isLoading: false,
    initial: true,
} as SearchState


export const fetchSearch = createAsyncThunk("listSearch/fetchSearch", async (body: SearchRequest) => {
    const response = await ListSearchApi.post(body, null);
    return response.data;
})


const listSlice = createSlice({
    name: 'listSearch',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSearch.pending, (state, action) => {
                state.isLoading = true;
                state.search = null;
            })
            .addCase(fetchSearch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.initial = false;
                state.search = action.payload
            })
            .addCase(fetchSearch.rejected, (state, action) => {
                state.isLoading = false;
                console.log("error while list:", action)
            })

    }
})

export const { } = listSlice.actions;

export default listSlice.reducer;
export const listSelector = (state: RootState) => state.listSearch;