import React, { useState } from 'react';
import Button, { ButtonPropsColorOverrides } from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { promises } from 'fs';
/*
USAGE
import useConfirm from '@/component/ConfirmDialog';
...
const [getConfirmation, ConfirmationDialog] = useConfirm();
...
const status = await getConfirmation({ title: 'Tallenna', text: 'ootko varma', okText: 'Ok', cancelText: 'Ei' });
...
<ConfirmationDialog />
*/
const createPromise = () => {
    let resolver;
    return [new Promise((resolve, reject) => {
        resolver = resolve;
    }), resolver];
};

interface getConfirmationProps {
    title: string,
    text: string,
    okText: string,
    cancelText: string,
    buttonColorWarning?: boolean
}

const useConfirm = (): [any, any] => {
    const [open, setOpen] = useState(false);
    const [resolver, setResolver] = useState<any>({ resolver: null });
    const [label, setLabel] = useState<getConfirmationProps>({ title: '', text: '', okText: '', cancelText: '', buttonColorWarning: true });
    //params:{title,text, okText,cancelText}
    const getConfirmation = async (params: any) => {
        setLabel({ ...label, ...params });
        setOpen(true);
        const [promise, resolve] = await createPromise();
        setResolver({ resolve });
        return promise;
    };

    const onClick = async (status: boolean) => {
        setOpen(false);
        resolver.resolve(status);
    };

    const ConfirmationDialog = () => {
        return (
            <Dialog
                open={open}
                onClose={() => onClick(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

            >
                <DialogTitle id="alert-dialog-title">
                    {label.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {label.text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={{ p: '16px' }}
                >
                    <Button
                        sx={{ marginRight: '8px' }}
                        onClick={() => onClick(false)} variant='outlined' >{label.cancelText}</Button>
                    <Button
                        onClick={() => onClick(true)}
                        color={label.buttonColorWarning ? 'error' : 'primary'}
                        variant='contained'
                    >
                        {label.okText}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    };
    return [getConfirmation, ConfirmationDialog];
};

export default useConfirm;