import React, { useState, useEffect } from 'react';

import {
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Link,
    Typography,
    TextField,
    Button,
    Switch
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppDispatch, useAppSelector } from '../lib/hook';
import { authSelector, changePassword, deleteUserAndLogout, logout, requestLogoutAllAndLogout, requestLogoutAndLogout, setDarkTheme } from '../lib/ducks/authDuck';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { LoadingButton } from '@mui/lab';
import LogoutIcon from '@mui/icons-material/Logout';
import DeleteIcon from '@mui/icons-material/Delete';
import { AlertDialog } from '../components/dialogs/dialogs';

interface ComponentProps {
}

const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{12,}$/;

const SettingsView: React.FC<ComponentProps> = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [reNewPassword, setReNewPassword] = useState('');
    const [errors, setErrors] = useState({ newPassword: false, reNewPassword: false })
    const dispatch = useAppDispatch()
    const { darkTheme, isLoading } = useAppSelector(authSelector);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertAllOpen, setAlertAllOpen] = useState(false);
    const [alertDeleteOpen, setAlertDeleteOpen] = useState(false);


    const handleSavePassword = () => {
        setErrors({ newPassword: false, reNewPassword: false })
        if (!passwordRegex.test(newPassword)) {
            setErrors(o => ({ ...o, newPassword: true }))
            return
        }

        if (newPassword != reNewPassword) {
            setErrors(o => ({ ...o, reNewPassword: true }))
            return
        }

        dispatch(changePassword({ old_password: oldPassword, password: newPassword }))
    }

    return (
        <>
            <AlertDialog
                title='Haluatko varmasti kirjautua ulos?'
                text=''
                okText='Kyllä'
                cancelText='Peruuta'
                open={alertOpen}
                handleClose={() => setAlertOpen(false)}
                submit={() => dispatch(requestLogoutAndLogout())}
            />
            <AlertDialog
                title='Haluatko varmasti kirjautua ulos kaikista istunnoista?'
                text=''
                okText='Kyllä'
                cancelText='Peruuta'
                open={alertAllOpen}
                handleClose={() => setAlertAllOpen(false)}
                submit={() => dispatch(requestLogoutAllAndLogout())}
            />
            <AlertDialog
                title='Haluatko varmasti poistaa käyttäjäsi?'
                text='Tämän jälkeen tunnuksesi ja tietosi poistetaan pysyvästi, eikä tietoja voida palauttaa'
                okText='Kyllä'
                cancelText='Peruuta'
                open={alertDeleteOpen}
                handleClose={() => setAlertDeleteOpen(false)}
                submit={() => dispatch(deleteUserAndLogout())}
            />
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection={'column'}
            >
                <Box>
                    <Typography variant="h4" gutterBottom sx={{ marginTop: 5 }}>
                        Asetukset
                    </Typography>
                    <Accordion >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Vaihda salasana
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} sx={{}}>
                                <Box>
                                    <Typography variant="caption" display="block">
                                        Salasanan pituus tulee olla vähintään 12 merkkiä.
                                    </Typography>
                                    <Typography variant="caption" display="block">
                                        Salasanassa tulee olla vähintään yksi iso kirjain, yksi pieni kirjain ja yksi numero.
                                    </Typography>
                                    <Typography variant="caption" display="block">
                                        Salasana ei saa olla yleisesti käytettyjen salasanojen joukossa.
                                    </Typography>
                                    <Typography variant="caption" display="block">
                                        Salasanassa ei saa olla viittauksia käyttäjän tietoihin.
                                    </Typography>
                                </Box>
                                <TextField fullWidth sx={{ marginTop: 2, maxWidth: '80%' }} label="Nykyinen salasana" variant="standard" type="password"
                                    value={oldPassword}
                                    onChange={e => setOldPassword(e.target.value)}
                                    autoComplete="current-password"
                                />
                                <TextField fullWidth sx={{ marginTop: 2, maxWidth: '80%' }} label="Uusi salasana" variant="standard" type="password"
                                    value={newPassword}
                                    onChange={e => setNewPassword(e.target.value)}
                                    helperText={errors.newPassword ? "Tarkista salasanan vaatimukset" : ""}
                                    error={errors.newPassword}
                                />
                                <TextField fullWidth sx={{ marginTop: 2, maxWidth: '80%' }} label="Uusi salasana uudestaan " variant="standard" type="password"
                                    value={reNewPassword}
                                    onChange={e => setReNewPassword(e.target.value)}
                                    helperText={errors.reNewPassword ? "Salasanat eivät täsmää" : ""}
                                    error={errors.reNewPassword}
                                />
                                <LoadingButton sx={{ marginTop: 2 }}
                                    onClick={handleSavePassword}
                                    loading={isLoading}
                                >
                                    Tallenna
                                </LoadingButton>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    {/*tyyli*/}
                    <Box display={'flex'} flexDirection={'column'} sx={{ marginTop: 3 }}>
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>

                                <Typography>Tumma teema</Typography>
                                <DarkModeIcon sx={{ marginLeft: 1 }} />
                            </Box>
                            <Switch
                                checked={darkTheme}
                                onChange={() => dispatch(setDarkTheme(!darkTheme))}
                            />
                        </Box>
                    </Box>

                    <Box display={'flex'} flexDirection={'row'} sx={{ marginTop: 3 }}>
                        <Button
                            endIcon={<LogoutIcon />}
                            variant="outlined"
                            onClick={() => setAlertOpen(true)}
                        >
                            Kirjaudu ulos
                        </Button>
                    </Box>
                    <Box display={'flex'} flexDirection={'row'} sx={{ marginTop: 3 }}>
                        <Button
                            endIcon={<LogoutIcon />}
                            variant="outlined"
                            onClick={() => setAlertAllOpen(true)}
                        >
                            Kirjaudu ulos kaikista istunnoista
                        </Button>
                    </Box>
                    <Box display={'flex'} flexDirection={'row'} sx={{ marginTop: 3 }}>
                        <Button
                            endIcon={<DeleteIcon />}
                            color="error"
                            variant="contained"
                            onClick={() => setAlertDeleteOpen(true)}
                        >
                            Poista käyttäjä
                        </Button>
                    </Box>



                </Box>
            </Box>
        </>
    )

};

export default SettingsView;