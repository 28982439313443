import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { IconButton, ImageListItemBar } from '@mui/material';
import CheckIcon from '@mui/icons-material/CheckCircle';

const createPromise = () => {
    let resolver;
    return [new Promise((resolve, reject) => {
        resolver = resolve;
    }), resolver];
};
export const useAvatarDialog = (): [any, any] => {
    const [open, setOpen] = useState(false);
    const [resolver, setResolver] = useState<any>({ resolver: null });
    const [label, setLabel] = useState({ nameNeeded: false, avatarName: 'default.png' });
    useEffect(() => {
        console.log("useAvatarDialog rendering");
    }, []);
    //params:{title,text, okText,cancelText}
    const getForm = async (params: any) => {
        setLabel(params);
        setOpen(true);
        const [promise, resolve] = await createPromise();
        setResolver({ resolve });
        return promise;
    };
    const onClick = async (status: boolean, firstName: string, lastName: string, avatarName: string) => {
        setOpen(false);
        resolver.resolve({ status, firstName, lastName, avatarName });
    };

    const AvatarDialog = () => {
        const [firstName, setFirstName] = useState('');
        const [lastName, setLastName] = useState('');
        const [avatarName, setAvatarName] = useState('');
        useEffect(() => {
            setAvatarName(label.avatarName === "" ? "default.png" : label.avatarName);
            console.log("generate AvatarDialog", label.avatarName, avatarName)
        }, []);

        return (
            <Dialog
                keepMounted
                open={open}
                onClose={() => onClick(false, '', '', '')}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                PaperProps={{ sx: { minWidth: '900px' } }}
            >
                <DialogTitle id="alert-dialog-title">
                    Vaihda kuvake
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Valitse profiilikuvasi
                    </DialogContentText>
                    {label.nameNeeded &&
                        <>
                            <TextField
                                autoFocus
                                placeholder={"Etunimi"}
                                required
                                margin="dense"
                                id="avatardialogname"
                                fullWidth
                                variant="standard"
                                value={firstName}
                                onChange={(event) => {
                                    setFirstName(event.target.value);
                                }}
                            />
                            <TextField
                                autoFocus
                                placeholder={"Etunimi"}
                                required
                                margin="dense"
                                id="avatardialoglastname"
                                fullWidth
                                variant="standard"
                                value={firstName}
                                onChange={(event) => {
                                    setFirstName(event.target.value);
                                }}
                            />
                        </>
                    }
                    <ImageList sx={{ width: 820, height: 420 }} cols={6} rowHeight={128} gap={8}>
                        {itemData().map((item) => (
                            <ImageListItem key={item.name}>
                                <img
                                    onClick={() => setAvatarName(item.name)}
                                    srcSet={`/images/avatar/${item.name}?w=128&h=128&fit=crop&auto=format&dpr=2 2x`}
                                    src={`/images/avatar/${item.name}?w=128&h=128&fit=crop&auto=format`}

                                    loading="lazy"
                                />
                                <ImageListItemBar

                                    sx={{
                                        background:
                                            'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                    }}
                                    position="top"
                                    actionIcon={
                                        <IconButton
                                            sx={{ color: 'white' }}
                                            onClick={() => setAvatarName(item.name)}
                                        >
                                            <CheckIcon color={avatarName === item.name ? "primary" : undefined} />
                                        </IconButton>
                                    }
                                    actionPosition="left"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClick(false, '', '', '')}>Peruuta</Button>
                    <Button onClick={() => onClick(true, firstName, lastName, avatarName)} variant='contained'>
                        Tallenna
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
    return [getForm, AvatarDialog];
};

const itemData = () => {
    let list = [{ name: "default.png" }]
    console.log("generate itemdata")
    for (let i = 0; i < 17; i++) {
        list.push({ name: i.toString() + '.png' });
    }
    return list;
}