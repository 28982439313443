
import React, { useState } from "react";
import { Container, Box, Paper, Stack, TextField, Grow, Typography, Button } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { authSelector, login, setAlert } from "../lib/ducks/authDuck";
import { useAppDispatch, useAppSelector } from "../lib/hook";
import LoadingButton from '@mui/lab/LoadingButton';
import { LoginApi } from "../lib/apiActions";
import { useParams } from 'react-router-dom';
import AlertHandler from "../components/alertHandler";
import { useNavigate } from "react-router-dom";

const themeLight = createTheme({
    palette: {
        mode: 'light',
        bubbleColor: "grey.900",
        boxShade: '#0e0e0e99',
        responseBubbleColor: "grey.900",
        sidePanel: "#171526",
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
            }
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {

                root: {

                    borderRadius: '8px',
                    padding: '12px 24px',
                    textTransform: "none",
                },
                containedPrimary: {
                    backgroundColor: '#3A52D1',
                    //color: '#FFFFFF',
                },
                outlinedPrimary: {
                    color: '#545455',
                    borderColor: '#545455',
                },
                textPrimary: {
                    color: '#545455',
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    // Puts the label into notched position
                    '.MuiInputLabel-outlined:has(+ .MuiOutlinedInput-root > .MuiOutlinedInput-input:-webkit-autofill)': {
                        transform: 'translate(14px, -9px) scale(0.75)',
                    },
                    // Creates a hole around the text inside the border
                    '.MuiOutlinedInput-input:-webkit-autofill + .MuiOutlinedInput-notchedOutline > legend': {
                        maxWidth: '100%',
                    },
                },
            },
        },
    }
});
const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));
export default function ForgotPasswordView(props: any) {
    const [username, setUsername] = useState<string>('')
    const [isLoading, setIsloading] = useState(false);
    const dispacth = useAppDispatch();
    const navigate = useNavigate()

    const resetPress = async () => {

        const body = {
            email: username,
        }
        setIsloading(true);
        const result = await LoginApi.forgotPassword(body, { validateStatus: false });
        if (result.status < 300) {
            dispacth(setAlert({ message: 'Salasanan vaihtopyyntö lähetetty sähköpostiin', type: 'success', key: (new Date()).toString() }))
            await sleep(3000)
            navigate("/login")
        } else {
            dispacth(setAlert({ message: 'Salasanan vaihtopyyntö epäonnistui', type: 'error', key: (new Date()).toString() }))
        }
        setIsloading(false);
    }

    return (
        <ThemeProvider theme={themeLight}>
            <CssBaseline />
            <Box sx={{ display: "flex", flexDirection: 'row', height: '100vh' }}>
                <Box sx={{
                    flex: 1, minWidth: '70%',
                    display: 'flex', justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#CFD4EA'
                }}>
                    <img
                        src="/images/login.gif"
                        height={'auto'}
                        width='100%'
                    />
                </Box>
                <Box sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column', alignItems: 'center',
                    padding: '48px'
                }}>
                    <Box
                        width={'100%'}
                        alignItems={'left'}
                    >
                        <img
                            src="/images/codex_logo_black.png"
                            height={'auto'}
                            width='137px'
                        />

                    </Box>

                    <Stack
                        marginTop={'124px'}
                        gap={'24px'}
                        width={'100%'}
                    >
                        <Typography variant="h6" gutterBottom align="center">
                            Pyydä salasanan vaihtolinkkiä
                        </Typography>
                        <TextField fullWidth label="Käyttäjätunnus"
                            value={username}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setUsername(event.target.value);
                            }}
                        />
                        <LoadingButton loading={isLoading} onClick={resetPress} variant="contained">Lähetä</LoadingButton>
                        {
                        /*
                            < Box display={"flex"} flexDirection={'row'} justifyContent='right' flexWrap='wrap'>
                        <Button variant="text">Unohdin salasanan</Button>
                        <Button variant="text">Pyydä Tunnuksia</Button>
                    </Box>
                        */}
                    </Stack>
                </Box>
            </Box>
            <AlertHandler />
        </ThemeProvider>
    )
}